<template>
  <b-card class="p-2">
    <b-row class="p-0 collection-edit">
      <b-col class="col-12 col-md-7 col-lg-6">
        <b-row>
          <b-col class="col-12 col-sm-6">
            <template>
              <div
                id="intro_collection_logo"
                class="image rounded object-contain"
                :class="{ 'preview-image-border': !logoPreview }"
              >
                <label for="collection_logo">
                  <b-img
                    v-if="logoPreview"
                    class="collectionLogo bg-gray img img-responsive fullwidth"
                    :src="logoPreview"
                    alt=""
                  />
                  <div
                    v-if="!logoPreview"
                    class="logo-preview"
                  >
                    <camera-icon />
                    <span
                      class="text-center font-weight-700 color-text-black"
                    >Upload collection <br>
                      cover photo
                    </span>
                  </div>
                </label>
              </div>
            </template>
          </b-col>
          <b-col class="mt-1 col-12 col-sm-6 mt-sm-0">
            <div class="mass-products-label mb-1">
              Mass Upload Products
            </div>
            <div
              id="intro_sample_product_sheet"
              class="mt-8-px"
            >
              <b-button
                variant="custom-secondary"
                block
                @click="onDownloadSampleSheet"
              >
                {{ isEdit ? 'Download Collection' : 'Sample Product Sheet' }}
              </b-button>
            </div>
            <div
              id="intro_collection_products"
              class="w-100"
            >
              <label
                class="w-100 rounded text-center preview-image-border upload-xlsx"
                :class="{ 'has-file': productFile }"
                for="collection_products"
              >
                <div
                  v-if="productFile && productFile.name"
                  class="elements-center w-100 color-text-black font-weight-700"
                >
                  <div>{{ textTruncate(productFile.name) }}</div>
                  <div
                    class="ml-8-px"
                    @click.prevent="productFile = null"
                  >
                    <collection-file-delete-icon />
                  </div>
                </div>
                <div
                  v-else
                  class="elements-center w-100 color-text-black font-weight-700"
                >
                  <div>Upload file</div>
                  <mass-upload-icon class="ml-8-px" />
                </div>
              </label>
              <b-form-file
                v-if="!productFile"
                id="collection_products"
                v-model="productFile"
                accept=".xlsx, .zip"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                plain
                hidden="true"
              />
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="col-12 col-md-5 col-lg-6 mt-1 mt-lg-0">
        <validation-observer
          ref="collectionForm"
          #default="{ invalid }"
        >
          <b-form @submit.prevent="syncCollection">
            <!-- name -->
            <b-form-group
              label="Collection Name*"
              label-for="intro_collection_name"
            >
              <validation-provider
                #default="{ errors }"
                name="Collection Name"
                vid="name"
                rules="required"
              >
                <b-form-input
                  id="intro_collection_name"
                  v-model="collection.name"
                  name="name"
                  placeholder="Collection Name"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- description -->
            <b-form-group
              label="Description*"
              label-for="intro_collection_description"
            >
              <validation-provider
                #default="{ errors }"
                name="Collection Description"
                vid="description"
                rules="required|min:3"
              >
                <b-form-input
                  id="intro_collection_description"
                  v-model="collection.description"
                  placeholder="Enter Collection Description..."
                  :state="errors.length > 0 ? false : null"
                  required
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- end date -->
            <div>
              <label> Collection's Products Availability*</label>
              <br>
              <b-form-radio-group
                id="file-radio-group"
                v-model="collection.type"
                name="file-radio-group"
                class="file-radio-btn"
              >
                <b-form-radio
                  v-for="collectionTypeOption in Object.values(
                    COLLECTION_TYPE
                  ).map((_) => _)"
                  :key="collectionTypeOption.TEXT"
                  :value="collectionTypeOption.LABEL"
                >
                  {{ collectionTypeOption.TEXT }}
                </b-form-radio>
              </b-form-radio-group>
            </div>
            <b-row>
              <b-col class="col-6 pr-50">
                <b-form-group
                  class="w-100"
                  label="Publish Date*"
                  label-for="intro_collection_publish_date"
                >
                  <flat-pickr
                    id="intro_collection_publish_date"
                    ref="startDate"
                    v-model="collection.startDateString"
                    class="form-control"
                    :config="{ dateFormat: KP_DATE_FORMAT.CONFIG }"
                    :placeholder="
                      collection.startDateString || KP_DATE_FORMAT.PLACEHOLDER
                    "
                  />
                </b-form-group>
              </b-col>
              <b-col class="col-6 pl-50">
                <b-form-group
                  class="w-100"
                  label="Order Deadline"
                  label-for="intro_collection_end_date"
                >
                  <flat-pickr
                    id="intro_collection_end_date"
                    ref="endDate"
                    v-model="collection.endDateString"
                    class="form-control"
                    :config="{ dateFormat: KP_DATE_FORMAT.CONFIG }"
                    :placeholder="
                      collection.endDateString || KP_DATE_FORMAT.PLACEHOLDER
                    "
                  />
                  <small
                    v-if="expireDateError"
                    class="text-danger"
                  >The Order Deadline is required!</small>
                </b-form-group>
              </b-col>
            </b-row>
            <b-form-group
              id="intro_collection_availability"
              class="w-100"
            >
              <template #label>
                <span class="font-small-4">Expected Delivery Window</span>
              </template>
              <b-row>
                <b-col class="col-6 pr-50">
                  <b-dropdown
                    class="delivery-window-dropdown w-100"
                    variant="outline-primary"
                    menu-class="scroll-bar mt-0"
                    :toggle-class="
                      lodash.isNumber(fromMonth)
                        ? 'color-body-color'
                        : 'color-sonic-silver'
                    "
                    no-caret
                    :text="
                      lodash.isNumber(fromMonth) ? months[fromMonth] : 'from'
                    "
                  >
                    <b-dropdown-item
                      v-for="(month, index) in months"
                      :key="`from_${month}`"
                      class="w-100"
                      :active="fromMonth === index"
                      @click="fromMonth = index"
                    >
                      {{ month }}
                    </b-dropdown-item>
                  </b-dropdown>
                </b-col>
                <b-col class="col-6 pl-50">
                  <b-dropdown
                    class="delivery-window-dropdown w-100"
                    variant="outline-primary"
                    menu-class="delivery-window-dropdown scroll-bar mt-0"
                    no-caret
                    :toggle-class="
                      lodash.isNumber(toMonth)
                        ? 'color-body-color'
                        : 'color-sonic-silver'
                    "
                    :text="lodash.isNumber(toMonth) ? months[toMonth] : 'to'"
                  >
                    <b-dropdown-item
                      v-for="(month, index) in months"
                      :key="`to_${month}`"
                      class="w-100"
                      :active="toMonth === index"
                      @click="toMonth = index"
                    >
                      {{ month }}
                    </b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
            </b-form-group>

            <!-- logo -->
            <b-form-file
              id="collection_logo"
              v-model="img"
              accept=".jpg,.png,.jpeg,.svg"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              plain
              hidden="true"
            />
            <!-- action -->
            <b-button
              type="submit"
              variant="custom-primary"
              class="font-weight-bold font-size-16-px mt-1"
              :disabled="invalid || disabled"
            >
              {{ buttonText }}
            </b-button>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
    <ai-catalog-demo v-if="enableDromoDemo" />
    <b-modal
      id="cropcoverphoto"
      ref="cropcoverphoto"
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      centered
      size="lg"
    >
      <div class="pb-1 mb-2 border-bottom">
        <span class="custom-modal-heading">Crop cover photo </span>
        <feather-icon
          class="float-right close-x-icon"
          icon="XIcon"
          size="24"
          @click="closeCrop"
        />
      </div>
      <div class="cropper-section mb-1">
        <cropper
          ref="cropper"
          class="cropper"
          :src="croppedImg"
          :stencil-props="{
            aspectRatio: 1 / 1,
          }"
          image-restriction="fit-area"
        />
        <div class="text-center mt-1">
          <b-button
            class="mr-1 font-weight-bolder"
            variant="outline-info"
            @click="closeCrop"
          >
            Cancel
          </b-button>
          <b-button
            class="ml-1"
            variant="info"
            @click="crop"
          >
            {{ isCropping ? 'Cropping....' : 'Crop' }}
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- Confirm Update Collection -->
    <b-modal
      id="confirm_orders_update_modal"
      size="lg"
      modal-class="modal-primary custom-model"
      centered
      hide-header-close
      hide-header
      hide-footer
    >
      <div>
        <div class="pb-1 border-bottom">
          <span
            class="custom-modal-heading"
          >Collection Update Disclaimer!</span>
          <feather-icon
            class="float-right close-x-icon"
            icon="XIcon"
            size="24"
            @click="$bvModal.hide('confirm_orders_update_modal')"
          />
        </div>
        <p class="mt-1 text-center product-message">
          Some SKUs with updated prices exist in orders from retailers. Would
          you like to update prices in not confirmed orders?
        </p>
      </div>
      <div>
        <div class="text-center my-1">
          <b-button
            class="mt-1 mr-2 px-sm-3 font-weight-bolder"
            variant="outline-info"
            @click="$bvModal.hide('confirm_orders_update_modal')"
          >
            Cancel
          </b-button>
          <b-button
            class="mt-1 mr-2 px-sm-3 font-weight-bolder"
            variant="info"
            @click="doNotUpdateOrders"
          >
            Do not update orders
          </b-button>
          <b-button
            variant="info"
            class="mt-1 px-sm-3 font-weight-bolder"
            @click="openOrdersPopop"
          >
            Update orders
          </b-button>
        </div>
      </div>
    </b-modal>
    <collection-edit-modals
      :orders="ordersToUpdate"
      :update-orders="updateOrders"
    />
    <dowload-catalog
      v-if="collectionId"
      :collection="collection"
    />
    <tool-kit :toolkit-page="TOOLKIT_PAGE.COLLECTION_EDIT" />
  </b-card>
</template>

<script>
import CollectionFileDeleteIcon from '@/@core/assets/svg-components/CollectionFileDeleteIcon.vue'
import MassUploadIcon from '@/@core/assets/svg-components/MassUploadIcon.vue'
import analytics from '@/@core/utils/analytics'
import {
  apiToastError,
  apiToastSuccess,
  apiToastWarning,
} from '@/@core/utils/toast'
import SampleSheet from '@/assets/sample/SampleProductSheet.xlsx'
import { dataURLtoFile, toBase64 } from '@/common-utils'
import constants, {
  IMAGE_COMPRESSER,
  TOOLKIT_PAGE,
  UPLOAD_CATALOG_FILE_TYPES,
  UPLOAD_COLLECTION_LOGO_FILE_TYPES,
} from '@/constants'
import {
  CREATE_COLLECTION,
  GET_COLLECTION_BY_ID,
  UPDATE_COLLECTION,
} from '@/store/modules/collection.module'
import { UPLOAD_DOCUMENTS } from '@/store/modules/common.module'
import {
  download,
  formatNumber,
  formatObject,
  parseErrorObject,
  textTruncate,
} from '@core/utils/utils'
import { constants as c, utils } from '@kingpin-global/kingpin-utils-frontend'

import { required } from '@validations'
import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormRadio,
  BFormRadioGroup,
  BImg,
  BModal,
  BRow,
  VBModal,
  VBTooltip,
} from 'bootstrap-vue'
import Compress from 'compress.js'
import lodash from 'lodash'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Cropper } from 'vue-advanced-cropper'
import { CameraIcon } from 'vue-feather-icons'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { Settings } from '@/Settings'
import AiCatalogDemo from './AiCatalogDemo.vue'
import DowloadCatalog from './DowloadCatalog.vue'
import CollectionEditModals from './CollectionEditModals.vue'

const {
 dateStringToTime,
  formattedDate,
  getFromAndToDate,
} = utils

const {
 COLLECTION_TYPE,
  FILE_TYPES,
  KP_DATE_FORMAT,
  MONTHS,
} = c

export default {
  components: {
    BCard,
    BRow,
    BForm,
    BFormGroup,
    BCol,
    BFormInput,
    BFormFile,
    BButton,
    BImg,
    BModal,
    flatPickr,
    BDropdown,
    BDropdownItem,
    AiCatalogDemo,
    // validations
    ValidationProvider,
    ValidationObserver,
    DowloadCatalog,
    Cropper,
    CameraIcon,
    BFormRadio,
    BFormRadioGroup,
    MassUploadIcon,
    CollectionFileDeleteIcon,
    CollectionEditModals,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'v-b-modal': VBModal,
  },
  setup() {
    return {
      KP_DATE_FORMAT,
    }
  },
  data() {
    return {
      collectionId: this.$route.params.id,
      collection: this.buildDefault(),
      logoFile: null,
      logoPreview: null,
      productFile: null,
      expireDateError: null,
      uploading: false,
      loading: false,
      uploadPercentage: 0,
      uploadTime: 30,
      uploadMessage: null,
      xlsxIcon: require('@/assets/images/icons/xlsxIcon.svg'),
      zipIcon: require('@/assets/images/icons/zipIcon.svg'),
      formatNumber,
      // validation
      required,
      img: null,
      croppedImg: null,
      isCropping: false,
      COLLECTION_TYPE,
      TOOLKIT_PAGE,
      payload: {},
      months: Object.values(MONTHS).map(_ => _.TEXT),
      fromMonth: null,
      toMonth: null,
      lodash,
      textTruncate,
      download,
      ordersToUpdate: [],
      enableDromoDemo: Settings.getConfig('ENABLE_DROMO_DEMO') === 'true',
    }
  },
  computed: {
    profile() {
      return this.$store.getters.profile
    },
    isBrand() {
      return this.$store.getters.isBrand
    },
    isEdit() {
      return !!this.collectionId
    },
    buttonText() {
      return this.isEdit ? 'Save Changes' : 'Add new collection'
    },
    title() {
      return this.isEdit
        ? `Edit collection "${this.collection.name}"`
        : 'Add collection'
    },
    disabled() {
      return (
        this.loading
        || this.uploading
        || !this.collection.type
        || !this.hasDates
      )
    },
    hasDates() {
      return (
        (lodash.isNumber(this.fromMonth) && lodash.isNumber(this.toMonth))
        || (this.fromMonth === null && this.toMonth === null)
      )
    },
  },
  watch: {
    logoFile: {
      async handler(theFile) {
        if (!theFile) return
        this.logoPreview = await toBase64(theFile)
      },
      immediate: true,
    },
    img: {
      async handler(theFile) {
        if (!theFile) return
        this.croppedImg = await toBase64(theFile)
        this.$refs.cropcoverphoto.show()
      },
      immediate: true,
    },
  },
  created() {
    if (this.collectionId) {
      this.fetchCollection()
    } else {
      this.updateBreadcrumb()
    }
  },
  methods: {
    onDropCollectionLogo(e) {
      const droppedFile = e.dataTransfer.files[0]
      const isSupportedFile = UPLOAD_COLLECTION_LOGO_FILE_TYPES.find(type => droppedFile.type.includes(type))
      if (!isSupportedFile) {
        apiToastError(
          'There is no support for this file format. Choose from .jpg, .png, .jpeg, or .svg',
        )
        return null
      }
      this.img = droppedFile
    },
    onDropProductFile(e) {
      const droppedFile = e.dataTransfer.files[0]
      const isSupportedFile = UPLOAD_CATALOG_FILE_TYPES.find(type => droppedFile.type.includes(type))
      if (!isSupportedFile) {
        apiToastError(
          'There is no support for this file format. Choose either .xlsx or .zip',
        )
        return null
      }
      this.productFile = droppedFile
    },
    async onDownloadSampleSheet() {
      if (this.collectionId) {
        this.$bvModal.show(`download_catalog_${this.collectionId}`)
      } else {
        download(SampleSheet)
      }
    },
    buildDefault() {
      return {
        name: '',
        description: '',
        endDate: '',
        startDate: '',
        startDateString: formattedDate(new Date()),
        endDateString: '',
        logo: '',
        productsFile: '',
        type: '',
      }
    },

    // Timer for Uploading Products
    uploadTimeHandler() {
      if (this.uploadTime > 0) {
        setTimeout(() => {
          this.uploadTime -= 1
          if (this.loading || this.uploading) {
            this.uploadTimeHandler()
          }
        }, 1000)
      }
    },
    resetForm() {
      this.collection = this.buildDefault()
    },
    trackCollectionError(err) {
      analytics.track(
        constants.TRACKS.ACTIONS.BRAND_ENDS_ADDING_OR_EDITING_COLLECTION,
        formatObject({
          collectionStatus: 'Error',
          error: parseErrorObject(err),
        }),
      )
    },
    async uploadLogo() {
      this.uploading = true
      if (this.logoFile instanceof File) {
        const loaderObj = {
          onUploadProgress: progressEvent => {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 20),
            )
            this.uploadMessage = `Uploading your Cover Photo... ${this.uploadPercentage}%`
            this.$root.$progressBar.value = {
              uploadPercentage: this.uploadPercentage,
              uploadMessage: this.uploadMessage,
              showProgress: true,
            }
          },
        }
        this.collection.logo = await this.$store.dispatch(UPLOAD_DOCUMENTS, {
          file: this.logoFile,
          loaderObj,
          fileType: FILE_TYPES.COLLECTION_LOGO,
        })
      }
      this.uploading = false
    },
    async uploadProduct() {
      this.uploading = true
      if (this.productFile instanceof File) {
        const allowedFileTypes = ['xlsx', 'zip', '7z'] // Example allowed file types
        const fileType = this.productFile.name.split('.').pop().toLowerCase()
        if (!allowedFileTypes.includes(fileType)) {
          apiToastWarning('Invalid file type. Please upload a valid file.')
          this.uploading = false
          this.resetLoading()
          return false
        }
        const loaderObj = {
          onUploadProgress: progressEvent => {
            this.uploadPercentage = 20
              + parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 50),
              )
            // If Upload Time reaches more than 30 seconds This might take few seconds... Please wait will be displayed
            if (this.uploadTime !== 0) {
              this.uploadMessage = `Uploading your File... ${this.uploadPercentage}%`
            } else {
              this.uploadMessage = 'This might take few seconds... Please wait'
            }
            this.$root.$progressBar.value = {
              uploadPercentage: this.uploadPercentage,
              uploadMessage: this.uploadMessage,
              showProgress: true,
            }
          },
        }
        this.collection.productsFile = await this.$store.dispatch(
          UPLOAD_DOCUMENTS,
          {
            file: this.productFile,
            loaderObj,
            fileType: FILE_TYPES.COLLECTION_PRODUCTS,
          },
        )
      }
      this.uploading = false
      return true
    },
    resetLoading() {
      this.loading = false

      // If any error comes progressbar will get disappeared
      this.$root.$progressBar.value = {
        showProgress: false,
      }
      this.uploadPercentage = 0
    },
    syncCollection() {
      // validate forms
      this.$refs.collectionForm
        .validate()
        .then(async success => {
          if (success) {
            this.loading = true
            this.uploadTimeHandler()

            // Initialize Progress Bar

            this.$root.$progressBar.value = {
              showProgress: true,
            }
            // upload logo first
            await this.uploadLogo(this.collectionId)
            // // upload products
           const shouldProceed = await this.uploadProduct(this.collectionId)
           if (!shouldProceed) {
            return
           }
            // prepare payload
            const {
              name,
              description,
              endDateString,
              startDateString,
              logo,
              type,
              productsFile,
            } = this.collection
            const payload = {
              name,
              description,
              logo,
              productsFile,
              type,
              expectedDeliveryWindow: {},
            }
            if (startDateString) {
              payload.startDate = new Date(dateStringToTime(startDateString))
            }
            if (endDateString) {
              payload.endDate = new Date(dateStringToTime(endDateString))
            }
            if (
              lodash.isNumber(this.fromMonth)
              && lodash.isNumber(this.toMonth)
            ) {
              payload.expectedDeliveryWindow = getFromAndToDate(
                this.fromMonth,
                this.toMonth,
              )
            }
            if (!this.logoFile) delete payload.logo
            if (!this.productFile) delete payload.productsFile
            // submit collection

            // Third  request is converted to 30% for Saving the Products

            try {
              if (this.collectionId) {
                this.payload = payload
                this.updateCollection()
              } else {
                const loaderObj = {
                  onUploadProgress: progressEvent => {
                    this.uploadPercentage = 70
                      + parseInt(
                        Math.round(
                          (progressEvent.loaded / progressEvent.total) * 30,
                        ),
                      )
                    this.uploadMessage = `Saving Your Products... ${this.uploadPercentage}%`
                    this.$root.$progressBar.value = {
                      uploadPercentage: this.uploadPercentage,
                      uploadMessage: this.uploadMessage,
                      showProgress: true,
                    }
                  },
                }
                this.$store
                  .dispatch(CREATE_COLLECTION, { payload, loaderObj })
                  .then(res => {
                    analytics.track(
                      constants.TRACKS.ACTIONS
                        .BRAND_ENDS_ADDING_OR_EDITING_COLLECTION,
                      formatObject({ ...payload, collectionStatus: 'Success' }),
                    )
                    analytics.track(
                      constants.TRACKS.ACTIONS.BRAND_COLLECTION_FILE_PROCEEDED,
                      formatObject(res),
                    )
                    apiToastSuccess(res.data.message)
                    this.resetLoading()
                    this.$router.push({ name: 'collections' })
                  })
                  .catch(err => {
                    apiToastWarning(err)
                    this.resetLoading()
                    this.trackCollectionError(err)
                    analytics.track(
                      constants.TRACKS.ACTIONS.BRAND_COLLECTION_FILE_PROCEEDED,
                      formatObject(parseErrorObject(err)),
                    )
                  })
              }
            } catch (err) {
              apiToastWarning(err)
              this.resetLoading()
              this.trackCollectionError(err)
            } finally {
              this.resetLoading()
            }
          }
        })
        .catch(err => {
          apiToastWarning(err)
          this.resetLoading()
          this.trackCollectionError(err)
        })
    },
    doNotUpdateOrders() {
      this.payload = {
        ...this.payload,
        shouldUpdateProductPrices: false,
      }
      this.updateCollection()
    },
    openOrdersPopop() {
      this.$bvModal.hide('confirm_orders_update_modal')
      this.$bvModal.show('select_orders_modal')
    },
    updateOrders(orderIds) {
      this.payload = {
        ...this.payload,
        shouldUpdateProductPrices: true,
        orderIds,
      }
      this.updateCollection()
    },
    async updateCollection() {
      const loaderObj = {
        onUploadProgress: progressEvent => {
          this.uploadPercentage = 70
            + parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 30),
            )
          this.uploadMessage = `Saving Your Products... ${this.uploadPercentage}%`
          this.$root.$progressBar.value = {
            uploadPercentage: this.uploadPercentage,
            uploadMessage: this.uploadMessage,
            showProgress: true,
          }
        },
      }
      await this.$store
        .dispatch(UPDATE_COLLECTION, {
          collectionId: this.collectionId,
          payload: this.payload,
          loaderObj,
        })
        .then(res => {
          this.$bvModal.hide('confirm_orders_update_modal')
          this.resetLoading()
          apiToastSuccess(res.data.message)
          analytics.track(
            constants.TRACKS.ACTIONS.BRAND_ENDS_ADDING_OR_EDITING_COLLECTION,
            formatObject({ collectionStatus: 'Success' }),
          )
          this.$router.push({ name: 'collections' })
        })
        .catch(err => {
          if (
            this.productFile instanceof File
            && err.response.status === 441
            && err.response.data.data.shouldUpdateProductPrices
          ) {
            if (err?.response?.data?.data?.orders.length) {
              this.ordersToUpdate = err.response.data.data.orders
            }
            this.$bvModal.show('confirm_orders_update_modal')
          } else {
            apiToastWarning(err)
            this.trackCollectionError(err)
          }
          this.resetLoading()
        })
    },
    fetchCollection() {
      this.$store
        .dispatch(GET_COLLECTION_BY_ID, this.collectionId)
        .then(res => {
          const collection = res.data.data
          this.collection = {
            ...this.collection,
            ...collection,
          }
          this.collection.endDateString = (this.collection.endDate && new Date(this.collection.endDate))
            || null
          this.collection.startDateString = (this.collection.startDate
              && new Date(this.collection.startDate))
            || null
          this.fromMonth = this.collection.expectedDeliveryWindow?.fromDate
            ? new Date(
                this.collection.expectedDeliveryWindow.fromDate,
              ).getMonth()
            : null
          this.toMonth = this.collection?.expectedDeliveryWindow?.toDate
            ? new Date(
                this.collection.expectedDeliveryWindow?.toDate,
              ).getMonth()
            : null
          if (this.collection.logo) this.logoPreview = this.collection.logo
          this.updateBreadcrumb()
        })
    },
    updateBreadcrumb() {
      this.$route.meta.breadcrumb = this.$route.meta.breadcrumb.map(item => {
        if (item.dynamic === true) {
          if (item.hasOwnProperty('actionName')) {
            item.actionName = this.collection.name
              ? `Edit <b>${this.collection.name}</b>`
              : 'Add Collection'
          }
        }
        return item
      })
    },
    /* checkExpireDate(value) {
      this.expireDateError = !value.length
    } */
    toggleDatePicker() {
      this.$refs.endDate.$el._flatpickr.toggle()
    },
    toggleStartDatePicker() {
      this.$refs.startDate.$el._flatpickr.toggle()
    },
    closeCrop() {
      this.img = null
      this.$refs.cropcoverphoto.hide()
    },
    async crop() {
      this.isCropping = true
      const { canvas } = this.$refs.cropper.getResult()
      const logoFile = canvas.toDataURL()
      let file = dataURLtoFile(logoFile, this.img.name)
      if (file.size / 1024 > 1024) {
        const compress = new Compress()
        const data = await compress.compress([file], IMAGE_COMPRESSER)
        file = dataURLtoFile(data[0].prefix + data[0].data, this.img.name)
      }
      this.logoFile = file
      this.$refs.cropcoverphoto.hide()
      this.img = null
      this.isCropping = false
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-collection.scss';
@import '@core/scss/vue/libs/vue-advanced-cropper.scss';
</style>
