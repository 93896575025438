<template>
  <b-row>
    <div>
      <div>
        <br>
        <br>
        <h2 class="my-2">
          AI Catalog Demo
        </h2>
        <div>
          <!--div className="form-field">
            <label htmlFor="name">Webhook URL</label>
            <input
              id="webhookUrl"
              v-model="webhookUrl"
              type="text"
              name="name"
            >
          </div-->
          <b-form-group label="Images in ZIP">
            <b-form-textarea
              v-model="filesInZip"
              class="text-area"
              max-rows="4"
            />
          </b-form-group>
          <b-button
            variant="primary"
            @click="startDromo"
          >
            Upload Products
          </b-button>
          <!--div class="form-field">
            <label htmlFor="downloadCommand">Download Command</label>
            <input
              id=""
              type="textarea"
              value="curl -H 'X-Dromo-License-Key: 753...' https://app.dromo.io/api/v1/upload/UPLOAD-ID/data/"
            >
          </div-->
        </div>
      </div>
    </div>
  </b-row>
</template>
<script>
import {
 BRow, BButton, BFormGroup, BFormTextarea,
} from 'bootstrap-vue'
import DromoUploadCatalogHelper from '@/dromo/DromoUploadCatalogHelper'
import DromoUploader from 'dromo-uploader-js'

export default {
  name: 'AiCatalogDemo',
  components: {
    BRow,
    BButton,
    BFormGroup,
    BFormTextarea,
  },
  props: {
    value: {
      type: String,
      default: 'image1.jpg\nimage2.jpg\nimage3.jpg',
    },
  },
  data() {
    return {
      webhookUrl: 'https://flaky-market-26.webhook.cool',
      filesInZip: 'image1.jpg\nimage2.jpg\nimage3.jpg',
    }
  },
  methods: {
    startDromo() {
      const license = '5091bc92-856e-4409-9ed2-df2abdfcf91d'

      const filesInZipList = this.filesInZip.split('\n')
      const dromoHelper = new DromoUploadCatalogHelper(dromo, filesInZipList)

      const fields = dromoHelper.fields()
      const settings = dromoHelper.settings()
      const profile = this.$store.getters.profile

      const user = {
        id: profile?.id,
        name: `${profile?.firstName } ${ profile?.lastName}`,
        email: profile?.email,
        companyId: '', // TODO:
        companyName: profile?.entityName,
      }

      const dromo = new DromoUploader(license, fields, settings, user)
      dromoHelper.registerHooks(dromo)
      // dromo.onResults(function (response, metadata) {
      //   // Do something with the data here
      //   return "Done!";
      // });
      dromo.open()
    },
  },
}
</script>
<style lang="scss">
.text-area {
  width: 300px;
}
</style>
